<template>
    <div class="mt-5">
        <v-dialog max-width="600px" v-model="open">
            <v-card>
                <v-card-title>
                    <span class="headline" v-if="mode == 'enable'">2-Faktor Einrichtung</span>
                    <span class="headline" v-if="mode == 'add'">2-Faktor Gerät hinzufügen</span>
                    <span class="headline" v-if="mode == 'remove'">2-Faktor Gerät entfernen</span>
                    <span class="headline" v-if="mode == 'verify'">2-Faktor Gerät bestätigen</span>
                    <span class="headline" v-if="mode == 'disable'">2-Faktor deaktivieren</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-tabs-items v-model="step">
                            <v-tab-item>
                                Backup Code: {{ authCode }}
                            </v-tab-item>
                            <v-tab-item>
                                <v-text-field v-model="title" label="Titel" />
                                <v-select v-model="provider" label="Provider" :items="Object.keys(providers).filter(p => p != 'BACKUP').map(p => ({ value: p, text: providers[p] }))" />
                            </v-tab-item>
                            <v-tab-item>
                                Verifizieren
                                <v-select v-model="authProvider" label="Provider" :items="Object.keys(providers).map(p => ({ value: p, text: providers[p] }))" />
                                <v-text-field v-model="authCode" label="Code" />
                            </v-tab-item>
                            <v-tab-item>
                                <strong>1. Scanne den QR-Code</strong>
                                <br>
                                <img :src="device.totp_qr_code">
                                <br>
                                <small>Secret: {{ device.totp_secret }}</small>
                                <br>
                                <br>
                                <strong>2. Gebe den einmaligen Code ein</strong>
                                <v-text-field v-model="verifyCode" label="Code" />
                            </v-tab-item>
                        </v-tabs-items>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="cancel()">Abbrechen</v-btn>
                    <v-btn @click="next()" color="primary">Weiter</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <datatable path="/auth/2fa-devices" :headers="[
            {text: 'ID', value: 'id'},
            {text: 'Titel', value: 'title'},
            {text: 'Provider', value: 'provider'},
            {text: 'Bestätigt', value: 'verified'},
            {text: 'Aktionen', value: 'actions'}
        ]" v-if="$store.state.current_user.two_factor" @data="data => devices = data" ref="table">
            <template v-slot:actions>
                <v-btn color="primary" @click="add()" class="mr-2">Gerät hinzufügen</v-btn>
                <v-btn color="red" @click="disable()" class="white--text">2-Faktor deaktivieren</v-btn>
            </template>
            <template v-slot:[`item.verified`]="{ item }">
                {{ item.verified ? 'Ja' : 'Nein' }}
            </template>
            <template v-slot:[`item.provider`]="{ item }">
                {{ providers[item.provider] }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn small color="red" class="white--text" @click="(devices.length > 1 ? remove : disable)(item)">Entfernen</v-btn>
                <v-btn small color="primary" @click="verify(item)" class="ml-1" v-if="!item.verified">Bestätigen</v-btn>
            </template>
        </datatable>
        <div v-else class="text-center">
            <v-btn x-large color="primary" class="mt-5 mb-5" @click="enable()">2-Faktor Anmeldung aktivieren</v-btn>
        </div>
    </div>
    
</template>

<script>
import xeonserv from '../../../apis/xeonserv'

const DEFAULT_DEVICE = {
    id: 0,
    provider: 'TOTP',
    totp_secret: '',
    totp_qr_code: ''
}

export default {
    data() {
        return {
            devices: [],
            providers: {
                TOTP: 'TOTP (Google Authenticator, Authy, ...)',
                BACKUP: 'Backup Code'
            },
            mode: '',
            title: '',
            step: 0,
            verifyCode: '',
            open: false,
            provider: 'TOTP',
            authProvider: 'TOTP',
            authCode: '',
            device: {...DEFAULT_DEVICE}
        }
    },
    methods: {
        enable() {
            xeonserv().post('/auth/2fa-devices', { provider: 'BACKUP', title: 'Backup' }).then(res => {
                this.mode = 'enable'
                this.$store.dispatch('loadCurrentUser')
                this.authProvider = res.data.data.provider
                this.authCode = res.data.data.backup_code
                this.step = 0
                this.open = true
            })
        },
        disable() {
            this.mode = 'disable'
            this.step = 2
            if(this.devices.filter(d => d.verified).length > 0) {
                this.open = true
            } else {
                this.next()
            }
        },
        remove(device) {
            this.mode = 'remove'
            this.step = 2
            this.authCode = ''
            this.device = device
            if(device.verified) {
                this.open = true
            } else {
                this.next()
            }
        },
        add() {
            this.mode = 'add'
            this.step = 1
            this.authCode = ''
            this.open = true
        },
        verify(device) {
            this.mode = 'verify'
            this.step = 3
            this.verifyCode = ''
            this.device = device
            this.open = true
        },
        next() {
            if(this.step == 0) {
                this.step = 1
                return
            }
            if(this.step == 1) {
                if(this.mode == 'enable') {
                    xeonserv().post('/auth/2fa-devices', { provider: this.provider, auth_provider: this.authProvider, auth_code: this.authCode, title: this.title }).then(res => {
                        this.device = res.data.data
                        this.step = 3
                        this.$refs.table.$emit('reloaddata')
                    })
                }
                if(this.mode == 'add')
                    this.step = 2
                return
            }
            if(this.step == 2) {
                if(this.mode == 'add') {
                    xeonserv().post('/auth/2fa-devices', { provider: this.provider, auth_provider: this.authProvider, auth_code: this.authCode, title: this.title }).then(res => {
                        this.device = res.data.data
                        this.step = 3
                        this.$refs.table.$emit('reloaddata')
                    })
                }
                if(this.mode == 'disable') {
                    xeonserv().delete('/auth/2fa-devices', { data: { auth_provider: this.authProvider, auth_code: this.authCode } }).then(() => {
                        this.$store.dispatch('loadCurrentUser')
                        this.$refs.table.$emit('reloaddata')
                        this.open = false
                    })
                }
                if(this.mode == 'remove') {
                    xeonserv().delete('/auth/2fa-devices/' + this.device.id, { data: { auth_provider: this.authProvider, auth_code: this.authCode } }).then(() => {
                        this.open = false
                        this.$refs.table.$emit('reloaddata')
                    })
                }
                return
            }
            if(this.step == 3) {
                xeonserv().post('/auth/2fa-devices/' + this.device.id + '/verify', { code: this.verifyCode }).then(() => {
                    this.open = false
                    this.verifyCode = ''
                    this.device = {...DEFAULT_DEVICE}
                    this.$refs.table.$emit('reloaddata')
                })
            }
        },
        cancel() {
            if(this.mode == 'enable' && this.step < 3) {
                console.log(this.authProvider)
                console.log(this.authCode)
                xeonserv().delete('/auth/2fa-devices', { data: { auth_provider: this.authProvider, auth_code: this.authCode } }).then(() => {
                    this.authCode = ''
                    this.open = false
                    this.$store.dispatch('loadCurrentUser')
                })
                return
            }
            this.open = false
        }
    }
}
</script>