<template>
    <div class="px-page mt-5">
        <v-tabs fixed-tabs v-model="tab">
            <v-tab>
                Allgemein
            </v-tab>
            <v-tab>
                2-Faktor Anmeldung
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <AccountGeneral />
            </v-tab-item>
            <v-tab-item>
                <AccountTwoFactor />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import AccountGeneral from './AccountGeneral'
import AccountTwoFactor from './AccountTwoFactor'

export default {
    components: {
        AccountGeneral,
        AccountTwoFactor
    },
    data() {
        return {
            tab: 0
        }
    }
}
</script>